<template>
  <footer class="footer" :class="{ 'not-top': isNotTop }">
    <div class="content-wrapper">
      <div class="main-content">
        <div class="about-company">
          <router-link to="/" class="logo">
            <template v-if="$i18n.locale === 'ja'">
              <img
                src="/img/top/logo_white.min.svg"
                alt="ケミカン - SDSを正確にデータ化"
                width="177"
                height="52"
              />
            </template>

            <template v-else>
              <img
                src="/img/top/logo_white.en.png"
                alt="Chemical - Accurate SDS Data"
                width="177"
                height="52"
              />
            </template>
          </router-link>
          <p class="description">
            {{ $t('footer.aboutChemican1') }}
            <br />
            {{ $t('footer.aboutChemican2') }}
          </p>
        </div>
        <div class="link-list">
          <ul class="link-wrapper">
            <li class="link">
              <router-link to="/">
                {{ $t('footer.about') }}
              </router-link>
            </li>
            <li class="link">
              <router-link :to="{ name: 'top', hash: '#s03' }">
                {{ $t('footer.features') }}
              </router-link>
            </li>
            <li class="link">
              <router-link :to="{ name: 'top', hash: '#s05' }">
                {{ $t('footer.pricing') }}
              </router-link>
            </li>
          </ul>
          <ul v-if="$i18n.locale === 'ja'" class="link-wrapper">
            <li class="link">
              <router-link to="/signup">
                {{ $t('footer.freeTrial') }}
              </router-link>
            </li>
            <li class="link">
              <router-link to="/inquiry">
                {{ $t('footer.downloadMaterials') }}
              </router-link>
            </li>
            <li class="link">
              <router-link to="/login">
                {{ $t('footer.login') }}
              </router-link>
            </li>
          </ul>
          <ul class="link-wrapper">
            <li class="link">
              <router-link :to="{ name: 'corporate' }">
                {{ $t('footer.company') }}
              </router-link>
            </li>
            <li v-if="$i18n.locale === 'ja'" class="link">
              <a
                href="https://goodmoneyger.zendesk.com/hc/ja/requests/new"
                target="_blank"
              >
                {{ $t('footer.contact') }}
              </a>
            </li>
            <!--
              <li v-else class="link">
                <router-link to="/en/contact">
                  {{$t('footer.contact')}}
                </router-link>
              </li>
            -->
          </ul>
        </div>
      </div>
      <p class="copy-right">{{ $t('footer.copyright') }}</p>
    </div>
    <div class="bg-circle circle-1"></div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isNotTop: false,
    };
  },
  watch: {
    '$route.path': function (newPath) {
      this.isNotTop = newPath !== '/' && newPath !== '/corporate';
    },
  },
  created() {
    this.isNotTop =
      this.$route.path !== '/' && this.$route.path !== '/corporate';
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #eef8f6;
  background-image: url(/img/top/footer.min.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-top: 100px;
  padding-top: max(6.9444444444vw, 100px);
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  overflow: visible;

  &.not-top {
    background-color: #fff !important;
  }
}

.content-wrapper {
  background-color: #0f8277;
  margin: 0 auto;
  padding: 0 67px 30px;
  max-width: 1440px;
  width: 100%;
  text-align: left;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.main-content {
  display: flex;
  justify-content: space-between;
}

.about-company {
  .logo {
    width: 177px;
    height: 53px;
    display: block;
  }

  .description {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 142.8571428571%;
    color: #fff !important;
  }
}

.link-list {
  max-width: 660px;
  width: calc(100% - 300px);
  white-space: nowrap;
  display: flex;
}

.link-wrapper {
  width: 33.3%;
  display: block;
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 212.5%;
  color: #fff !important;
  display: block;

  a {
    text-decoration: none;
    color: #fff !important;
  }
}

.copy-right {
  margin-top: 117px;
  color: #6aaba5;
}

.circle-1 {
  width: 88px;
  height: 88px;
  top: 10px;
  left: calc(50% + 570px);
}

@media only screen and (max-width: 1440px) {
  .circle-1 {
    top: 10px;
    left: auto;
    right: 62px;
    z-index: 2;
  }
}

@media only screen and (min-width: 700px) and (max-width: 960px) {
  .content-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 810px) {
  .content-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-content {
    display: block;
  }

  .link-list {
    margin-top: 38px;
    max-width: 100%;
    width: 100%;
  }

  .copy-right {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 699px) {
  .footer {
    padding-top: 50px;
  }

  .link-list {
    margin-top: 38px;
    width: auto;
    display: block;
  }

  .link-wrapper {
    width: 100%;
    padding-left: 0;
  }

  .link {
    line-height: 275%;
  }

  .copy-right {
    margin-top: 72px;
    text-align: center;
  }

  .circle-1 {
    top: -25px;
    left: auto;
    right: 15px;
    z-index: 2;
  }
}
</style>
